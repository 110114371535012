import React, { useState, useEffect } from 'react';
import Accessibility from 'accessibility-options';
import { startAVI } from './../../shared/services/avi';

import {
  InfoPhrases,
  Loader,
  NetflixBanner,
  NetflixPopup,
  RegulationsWrapper,
  Svas,
  TopBar,
  UFSelector,
  BannerC6,
  BannerPrice,
  LgpdBanner,
  Footer
} from '../../shared/components';

import * as UFsService from '../../shared/services/ufs';
import * as RegulationsService from '../../shared/services/regulations';
import * as SvasService from '../../shared/services/svas';
import * as DataService from '../../shared/services/data';
import * as ParamsService from '../../shared/services/params';

const LightVendedorPage = () => {

  const pageName = 'light-vendedor';
  const [isUFSelectorVisible, toggleUFSelector] = useState(false);
  const [isNetflixPopupShow, toggleNetflixPopup] = useState(false);
  const [uf, changeUF] = useState('');
  const [plans, changePlans] = useState(null);
  const [content, changeContent] = useState({});
  const [isLoading, changeLoading] = useState(true);

  useEffect(() => {
    const accessibility = new Accessibility();
    const uf = UFsService.getUFByParams() || '';
    accessibility.init();
    loadData(uf);
    startAVI();
  }, []);

  const loadData = async uf => {
    changeLoading(true);
    const campaign = ParamsService.get('campaign') || 'vendedor';
    const { plans, content } = await DataService.getData(pageName, uf, campaign);
    changeUF(UFsService.getUFSelected(uf, plans[0]));
    changePlans(plans[0]);
    changeContent(content);
    changeLoading(false);
  };

  const handleChangeUF = e => {
    const uf = e.target.value;
    changeUF(uf);
  };

  const handleCloseUFSelector = () => {
    toggleUFSelector(false);
    loadData(uf);
  };

  return (
    <div>
      <TopBar uf={uf} onUFRequest={() => toggleUFSelector(true)} />

      <BannerPrice
        mobileContent={content.mobile}
        desktopContent={content.desktop}
        plan={plans}
        pageName={pageName}
        messages={content.infoPhrases}
      />

      <BannerC6/>

      <Svas sva={SvasService.getSocialSvas(pageName)} />

      <NetflixBanner handleClick={toggleNetflixPopup} />

      <Svas sva={SvasService.getTimSvas(pageName)} />

      <InfoPhrases messages={content.footerMsg} />

      <RegulationsWrapper
        regulations={RegulationsService.get(content.regulations, content.summaries, uf)}
        labelText="Faça download dos regulamentos e sumários"
      />

      <Footer template="-default" />

      <UFSelector
        visible={isUFSelectorVisible}
        handleCloseUFSelector={handleCloseUFSelector}
        selectedUF={uf}
        handleChangeSelect={e => handleChangeUF(e)}
        ufs={UFsService.getUFs()}
      />

      <NetflixPopup visible={isNetflixPopupShow} handleClose={() => toggleNetflixPopup(false)} />

      <LgpdBanner />

      <Loader isLoading={isLoading} />
    </div>
  );
};

export default LightVendedorPage;
